import { centrifuge } from "./centrifugo";
import { store } from "../../store/store";
import { setRates } from "../../store/statsReducer";

export const createRatesSubscription = (resubscribe, unsubscribe) => {
  const subscription = centrifuge.subscribe(`analytics:courses`, async function (res) {
    store.dispatch(setRates(res.data.data));
  });
  if (resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe(`analytics:courses`, async function (res) {
      store.dispatch(setRates(res.data.data));
    });
  } else if (unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};
