import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { TStrSelect } from "src/store/directionsReducer";
import {
  TCitySelect,
  TDirectionSelect,
  TTextCities,
  TTextLang,
  TTextSubType,
  TTextSubTypeList,
  TTextType,
  TTextVectors,
} from "../../../types";
import { OptionLabel } from "src/components/FormElements/CustomOption";
import arrow from "src/assets/images/arrow.svg";
import whiteArrow from "src/assets/images/whiteArrow.svg";

type TProps = {
  item: TTextCities;
  types: Array<TTextType>;
  subTypes: TTextSubTypeList[];
  setCurrentCity: Dispatch<SetStateAction<TCitySelect> | null>;
  setVector: Dispatch<SetStateAction<TDirectionSelect | null>>;
  setType: Dispatch<SetStateAction<TTextType>>;
  setSubType: Dispatch<SetStateAction<TTextSubType | null>>;
  setLang: Dispatch<SetStateAction<TStrSelect | null>>;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
};

export const ExistCity = ({
  item,
  types,
  subTypes,
  setVector,
  setCurrentCity,
  setType,
  setSubType,
  setLang,
  setIsCreate,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setOpen] = useState(false);

  const getTypes = (item) => {
    return `${item?.currency_from_type}-${item?.currency_to_type}`;
  };

  const allTypes = useMemo(
    () => [...new Set(item?.vectors?.map((el) => getTypes(el)))] || [],
    [item]
  );
  const [openedTypes, setOpenedTypes] = useState<Array<string>>(allTypes);

  return (
    <div className="-mx-8">
      <button
        type="button"
        className={`flex w-full justify-between items-center bg-glass-select p-8`}
        onClick={() => setOpen(!isOpen)}>
        <div className="font-semibold text-sm whitespace-pre">{item.city_name?.toUpperCase()}</div>
        <div className={`relative right-4 duration-300 ${!isOpen ? "rotate-180" : ""}`}>
          <img src={arrow} alt="раскрыть" width={12} height={12} />
        </div>
      </button>
      <div
        className={`flex flex-col gap-4 m-4 duration-300 ${
          isOpen ? "max-h-[70vh] py-8 overflow-auto" : "max-h-[0] py-0 overflow-hidden"
        }`}>
        {item.vectors?.map((vectorEl: TTextVectors, index: number) => {
          const vectorIndex = `${item.city_code}_${vectorEl.currency_from_name}-${vectorEl.currency_to_name}`;
          const isOpenedType = openedTypes?.includes(getTypes(vectorEl));
          const isTypeHeader =
            index === 0 || getTypes(vectorEl) !== getTypes(item?.vectors[index - 1]);
          return (
            <div
              key={vectorIndex}
              className={`${isOpenedType || isTypeHeader ? "block" : "hidden"}`}>
              {isTypeHeader && (
                <button
                  type="button"
                  className={`flex w-full justify-between items-center rounded-sm bg-font text-[#FFF] py-2 mb-4 font-semibold whitespace-pre h-[20px] pl-4 pr-[12px]`}
                  onClick={() => {
                    if (isOpenedType) {
                      setOpenedTypes(openedTypes?.filter((el) => el !== getTypes(vectorEl)));
                    } else {
                      setOpenedTypes([...openedTypes, getTypes(vectorEl)]);
                    }
                  }}>
                  {getTypes(vectorEl)?.toUpperCase()}
                  <div
                    className={`relative -right-4 duration-300 ${
                      isOpenedType ? "rotate-180" : ""
                    }`}>
                    <img src={whiteArrow} alt="раскрыть" width={12} height={12} />
                  </div>
                </button>
              )}
              <div
                className={`flex-col gap-2 border border-[#BABAC333] rounded-sm py-2 px-4 bg-[#BABAC311] hover:bg-white duration-300 ${
                  isOpenedType ? "flex" : "hidden"
                }`}>
                <div>{`${vectorEl.currency_from_name}-${vectorEl.currency_to_name}`}</div>
                {vectorEl?.languages?.map((langEl: TTextLang) => {
                  return (
                    <div
                      key={`${vectorIndex}_${langEl?.lang}`}
                      className="flex items-center gap-4 w-fit rounded-sm bg-[#BABAC333] px-4 py-2 font-semibold whitespace-pre">
                      <span className="text-lightFont">{langEl.lang}:</span>
                      {langEl.types?.map((typeEl) => {
                        const type = types?.find(
                          (typeItem) => typeItem.id === typeEl.type_id
                        ) as TTextType;
                        const currentSubType = subTypes
                          ?.find((el) => el.name === type?.name_en)?.data
                            ?.find(dataEl => dataEl?.text_subtype_name_en === typeEl?.text_subtype)
                        return (
                          <button
                            key={`${vectorIndex}_${langEl.lang}_${typeEl.type_id}`}
                            type="button"
                            className="px-4 pb-2 rounded-sm bg-[#32C56E] text-[#FFF] hover:bg-pistachio duration-300"
                            onClick={async () => {
                              setCurrentCity({
                                label: OptionLabel({
                                  label: item.city_name,
                                  isFirstError: false,
                                  textFirstError: "Нет включенных векторов на сайте",
                                  firstColor: "inherit",
                                  isSecondError: false,
                                  textSecondError: "Есть векторы с незаполненными текстами",
                                  secondColor: "#D7443E",
                                }),
                                title: item?.city_name,
                                value: item?.city_id,
                                isNotOnSite: false,
                                isNoTexts: false,
                              });
                              setVector({
                                title: `${vectorEl.currency_from_name}-to-${vectorEl.currency_to_name}`,
                                value: {
                                  title: `${vectorEl.currency_from_name}-${vectorEl.currency_to_name}`,
                                  lang: langEl.lang,
                                  city_id: item.city_id,
                                  currency_from_id: vectorEl.currency_from_id,
                                  currency_from_name: vectorEl.currency_from_name,
                                  currency_from_type: vectorEl.currency_from_type,
                                  currency_to_id: vectorEl.currency_to_id,
                                  currency_to_name: vectorEl.currency_to_name,
                                  currency_to_type: vectorEl.currency_to_type,
                                },
                              });
                              setType(type);
                              setSubType(currentSubType || null);
                              setLang({ label: langEl.lang, value: langEl.lang });
                              setIsCreate(true);
                              searchParams.set("lang", langEl.lang);
                              searchParams.set("type", typeEl.type_id?.toString());
                              searchParams.set("cur_city", item?.city_id?.toString());
                              if (!!currentSubType) {
                                searchParams.set("subtype", currentSubType?.id?.toString());
                              }
                              setSearchParams(searchParams);
                            }}>
                            {type?.name_ru} {!!currentSubType ? `/ ${currentSubType?.text_subtype_name_ru}` : ""}
                          </button>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
