import { Dispatch, SetStateAction } from "react";
import { TBaseModel, TProxy, TProxyFilter, TSourceModel } from "../types";
import { Checkbox } from "src/components/UI/checkbox/Checkbox";
import { MiniNotice } from "src/components/UI/notice/Notice";

type TProps = {
  filter: TProxyFilter;
  setFilter: Dispatch<SetStateAction<TProxyFilter>>;
  sources: Array<TSourceModel>;
  services: Array<TBaseModel>;
  targets: Array<TBaseModel>;
  proxies: Array<TProxy>;
};

export const ProxyFilter = ({ filter, setFilter, sources, services, targets, proxies }: TProps) => {
  const addFilter = (id, attr) => {
    if (attr !== "soon_exp") {
      if (filter[attr]?.includes(id)) {
        setFilter({ ...filter, [attr]: filter[attr]?.filter((el) => el !== id) });
      } else {
        setFilter({ ...filter, [attr]: [...filter[attr], id] });
      }
    } else {
      setFilter({ ...filter, [attr]: !filter[attr] });
    }
  };

  return (
    <div className="flex flex-col gap-8 text-xs text-left text-lightFont max-w-[300px] sm:max-w-[512px]">
      {!!sources?.length && (
        <div className="flex flex-col gap-4 rounded-lg shadow-smooth p-[10px] pr-[30px]">
          <div className="font-semibold mb-4">Источники</div>
          {sources?.map((el) => (
            <FilterItem
              key={el.id}
              arr={proxies}
              item={el}
              attr="sources"
              filter={filter}
              addFn={addFilter}
            />
          ))}
        </div>
      )}
      {!!services?.length && (
        <div className="flex flex-col gap-4 rounded-lg shadow-smooth p-[10px] pr-[30px]">
          <div className="font-semibold mb-4">Сервисы</div>
          {services?.map((el) => (
            <FilterItem
              key={el.id}
              arr={proxies}
              item={el}
              attr="services"
              filter={filter}
              addFn={addFilter}
            />
          ))}
        </div>
      )}
      {!!targets?.length && (
        <div className="flex flex-col gap-4 rounded-lg shadow-smooth p-[10px] pr-[30px]">
          <div className="font-semibold mb-4">Найти не забаненные</div>
          {targets?.map((el) => (
            <FilterItem
              key={el.id}
              arr={proxies}
              item={el}
              attr="targets"
              filter={filter}
              addFn={addFilter}
            />
          ))}
        </div>
      )}
      {!!proxies?.length && (
        <div className="rounded-lg shadow-smooth p-[10px] pr-[30px]">
          <div className={`flex gap-8 ${filter?.soon_exp ? "text-font" : ""}`}>
            <Checkbox checked={filter?.soon_exp} onChange={() => addFilter(0, "soon_exp")} />
            скоро истекут
          </div>
        </div>
      )}
    </div>
  );
};

const FilterItem = ({ arr, item, attr, filter, addFn }) => {
  const checked = filter[attr]?.includes(item.id);
  const defaultFilter = {
    sources: [],
    services: [],
    targets: [],
    soon_exp: false,
  };
  const filteredCount = arr?.filter((el) =>
    allProxyFilter(el, {
      ...defaultFilter,
      [attr]: attr !== "soon_exp" ? [item.id] : true,
    })
  )?.length;

  return (
    <div className={`relative flex gap-8 ${checked ? "text-font" : ""} w-full`}>
      <Checkbox checked={checked} onChange={() => addFn(item.id, attr)} />
      {item.name}
      {filteredCount > 0 && <MiniNotice count={filteredCount} isVisible right={-22} />}
    </div>
  );
};

export const allProxyFilter = (item, filter) => {
  const preparedFilter = { ...filter };
  delete preparedFilter.soon_exp;

  const fullFilterLength = Object.values(preparedFilter)?.reduce((a: [], b: []) =>
    a.concat(b)
  ) as Array<number | boolean>;
  return (
    ((!!filter.sources.length && filter.sources?.includes(item?.source_id)) ||
      (!!filter.services.length &&
        !!item?.reserved_by?.find((el) => filter.services?.includes(el))) ||
      (!!filter.targets.length && !item?.problems?.find((el) => filter.targets?.includes(el))) ||
      !fullFilterLength?.length) &&
    (filter.soon_exp ? item.soon_exp : true)
  );
};
