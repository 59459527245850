import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import devApi from "src/api/developers";
import { fetchRequest } from "src/helpers/fetchRequest";
import { setNotific } from "src/store/mainReducer";
import { TSourceModel, TProxySourceBalance } from "../types";

export const Balance = ({ sources }: { sources: TSourceModel[] }) => {
  const dispatch = useDispatch();
  const [balance, setBalance] = useState<TProxySourceBalance[]>([]);

  const fetchBalance = async () => {
    const { response, error } = await fetchRequest(devApi.getBalance());
    if (response) {
      setBalance(response);
    }
    if (error) {
      dispatch(setNotific({ type: "error", message: error?.statusText, request: "Баланс" }));
    }
  };

  useEffect(() => {
    fetchBalance();
  }, []);

  return (
    <div className="flex flex-col items-center gap-8 w-[300px] sm:w-[512px] text-left pb-[100px] ml-[20px] mr-[50px]">
      {sources?.map((el) => {
        const currentBalance = balance?.find((balanceEl) => balanceEl.source_id === el.id);
        return (
          <div
            key={el.id}
            className={`rounded-lg shadow-smooth p-[10px] pl-[20px] min-w-[300px] text-left text-xs`}>
            <b>{el.name}</b>
            {!!currentBalance && (
              <>
                <div className="grid grid-cols-[60px_1fr]">
                  <span>баланс:</span>
                  <b>{currentBalance?.balance}</b>
                </div>
                <div className="grid grid-cols-[60px_1fr]">
                  <span>email:</span>
                  <b>{currentBalance?.email}</b>
                </div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};
