import { Dispatch, SetStateAction, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ErrorTab } from "src/pages/Clusters/Timeline/components/Errors/ErrorTab";
import { OptionLabel } from "src/components/FormElements/CustomOption";
import { TStrSelect } from "src/store/directionsReducer";
import {
  TCitySelect,
  TDirectionSelect,
  TPreparedVector,
  TTextCities,
  TTextDirectionGet,
  TTextSubType,
  TTextSubTypeList,
  TTextType,
  TTextVectors,
  TVectorType,
  TextSubType,
  TextType,
} from "../../types";
import arrow from "src/assets/images/arrow.svg";

type TProps = {
  directionsData: TTextDirectionGet;
  preparedVectors: Array<Array<TPreparedVector>>;
  existLanguages: Array<string>;
  types: Array<TTextType>;
  subTypes: TTextSubTypeList[];
  setType: Dispatch<SetStateAction<TTextType>>;
  setSubType: Dispatch<SetStateAction<TTextSubType | null>>;
  setIsCreate: Dispatch<SetStateAction<boolean>>;
  setCity: Dispatch<SetStateAction<TCitySelect> | null>;
  setSelectedVectors: Dispatch<SetStateAction<Array<TDirectionSelect>>>;
  setLang: Dispatch<SetStateAction<TStrSelect | null>>;
  setCurrencyType: Dispatch<SetStateAction<TVectorType>>;
};

export const Problems = ({
  directionsData,
  preparedVectors,
  existLanguages,
  types,
  subTypes,
  setType,
  setSubType,
  setIsCreate,
  setCity,
  setSelectedVectors,
  setLang,
  setCurrencyType,
}: TProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState("");
  const [opened, setOpened] = useState(false);
  const necessaryTypes = [1, 2, 3];

  const errorTabs = useMemo(
    () => ({
      city: {
        title: "ГОРОД",
        icon: `\u2716`,
        color: "#000",
      },
      text: {
        title: "ТИПЫ",
        icon: `\u25CF`,
        color: "#E7444E",
      },
      lang: {
        title: "ЯЗЫКИ",
        icon: `\u25B2`,
        color: "#FCC815",
      },
      integrated: {
        title: "ВЫКЛ",
        icon: `\u25A0`,
        color: "#BABAC3",
      },
    }),
    []
  );

  const cityErrors = useMemo(() => {
    if (!!preparedVectors?.length) {
      return directionsData?.cities
        ?.map((cityEl: TTextCities) => ({
          ...cityEl,
          vectors: cityEl.vectors?.filter((el) => !el.languages.length && el.is_on_site),
        }))
        ?.filter((el) => !!el.vectors.length);
    } else {
      return [];
    }
  }, [preparedVectors]);

  const textErrors = useMemo(() => {
    if (!!preparedVectors?.length) {
      return preparedVectors
        ?.reduce((a, b) => a.concat(b))
        ?.filter((el) => !!el.languages?.length)
        ?.map((el) => ({
          ...el,
          skippedTypes: el.languages
            ?.map((langEl) => {
              const existTypes = langEl.types?.map((typeEl) => typeEl.type_id);
              return {
                lang: langEl.lang,
                types: necessaryTypes
                  ?.filter((necessaryType) => !existTypes?.includes(necessaryType))
                  ?.map((typeEl) => types?.find((typeItem) => typeItem.id === typeEl) as TTextType),
              };
            })
            ?.filter((el) => !!el.types?.length),
        }))
        ?.filter((vectorEl) => !!vectorEl.skippedTypes?.length);
    } else {
      return [];
    }
  }, [preparedVectors]);

  const integratedVectors = useMemo(() => {
    if (!!preparedVectors?.length) {
      return preparedVectors?.reduce((a, b) => a.concat(b))?.filter((el) => !el.is_on_site);
    } else {
      return [];
    }
  }, [preparedVectors]);

  const langErrors = useMemo(() => {
    if (!!preparedVectors?.length) {
      return preparedVectors
        ?.reduce((a, b) => a.concat(b))
        ?.filter((el) => el.languages?.length !== existLanguages?.length)
        ?.map((el) => ({
          ...el,
          skippedLanguages: existLanguages?.filter(
            (existLang) => !el.languages?.find((elLang) => elLang.lang === existLang)
          ),
        }));
    } else {
      return [];
    }
  }, [preparedVectors]);

  const errors = useMemo(
    () => ({
      city: cityErrors?.length || 0,
      text: textErrors?.length || 0,
      lang: langErrors?.length || 0,
      integrated: integratedVectors?.length || 0,
    }),
    [textErrors?.length, langErrors?.length, integratedVectors?.length]
  );

  const putCity = (el) => {
    setCity({
      label: OptionLabel({
        label: el.city_name,
        isFirstError: false,
        textFirstError: "Нет включенных векторов на сайте",
        firstColor: "inherit",
        isSecondError: true,
        textSecondError: "Есть векторы с незаполненными текстами",
        secondColor: "#D7443E",
      }),
      title: el?.city_name,
      value: el?.city_id,
      isNotOnSite: false,
      isNoTexts: true,
    });
  };

  const putVectorOption = ({
    city,
    vector,
    lang,
  }: {
    city?: TTextCities;
    vector: TPreparedVector | TTextVectors;
    lang: string;
  }) => {
    return {
      label: OptionLabel({
        label: `${vector.currency_from_name}-to-${vector.currency_to_name}`,
        isFirstError: false,
        textFirstError: "Не включен на сайте",
        firstColor: "inherit",
        isSecondError: true,
        textSecondError: "Нет текстов",
        secondColor: "#D7443E",
      }),
      title: `${vector.currency_from_name}-to-${vector.currency_to_name}`,
      value: {
        title: `${!city && "city_name" in vector ? vector?.city_name : city?.city_name}-${
          vector.currency_from_name
        }-to-${vector.currency_to_name}`,
        lang: lang,
        city_id: !city && "city_name" in vector ? vector?.city_id : (city?.city_id as number),
        currency_from_id: vector?.currency_from_id,
        currency_from_name: vector.currency_from_name,
        currency_from_type: vector.currency_from_type,
        currency_to_id: vector.currency_to_id,
        currency_to_name: vector.currency_to_name,
        currency_to_type: vector.currency_to_type,
      },
    };
  };

  if (!!preparedVectors?.length) {
    return (
      <div className="-my-8 border border-stale rounded-sm font-normal">
        {!!errors && !!Object.values(errors)?.find((el: number) => el !== 0) ? (
          <>
            <div className="flex justify-end items-center text-[10px]">
              {Object.entries(errorTabs)?.map((el) => (
                <ErrorTab
                  key={el[0]}
                  item={el}
                  count={errors[el[0]]}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                />
              ))}
              <button
                type="button"
                className={`flex items-center relative h-[20px] mr-8`}
                onClick={() => setOpened(!opened)}>
                <div className={`duration-300 ${!opened ? "rotate-180" : ""}`}>
                  <img src={arrow} alt="раскрыть" width={12} height={12} />
                </div>
              </button>
            </div>
            <div
              className={`flex flex-col gap-[20px] leading-4 text-left overflow-y-auto duration-300 px-8 ${
                opened ? "max-h-[70vh] py-8" : "max-h-[0] py-0"
              }`}>
              {!!cityErrors?.length && (!activeTab?.length || activeTab === "city") && (
                <div className="flex flex-col gap-8 rounded-sm border border-[#00000033] bg-[#0000000A] -m-4 p-4">
                  <div className="flex items-center gap-8 text-black font-semibold">
                    <div className="text-[18px] font-black leading-3">{errorTabs.city.icon}</div>
                    Города, в которых нет НИКАКИХ текстов по ВКЛЮЧЕННЫМ на сайте векторам
                  </div>
                  <small>Подробнее в желтых ошибках</small>
                  <div className="flex flex-col gap-4">
                    {cityErrors?.map((el, index) => (
                      <button
                        key={`integrated_${index}`}
                        type="button"
                        className="text-left duration-300 hover:bg-pistachio"
                        onClick={() => {
                          putCity(el);
                          setLang({ label: "RU", value: "RU" });
                          setCurrencyType({
                            currency_from_type: el.vectors[0]?.currency_from_type,
                            currency_to_type: el.vectors[0]?.currency_to_type,
                          });
                          const vectors = el?.vectors?.map((vectorEl) =>
                            putVectorOption({
                              city: el,
                              vector: vectorEl,
                              lang: "RU",
                            })
                          );
                          setSelectedVectors(vectors);
                          setOpened(false);
                          setIsCreate(true);
                          searchParams.set("city", el?.city_id?.toString());
                          setSearchParams(searchParams);
                        }}>
                        <b className="font-semibold">{el.city_name}</b> - {el.vectors?.length}{" "}
                        вектор{el.vectors?.length > 4 ? "ов" : el.vectors?.length > 1 ? "а" : ""}
                      </button>
                    ))}
                  </div>
                </div>
              )}
              {!!textErrors?.length && (!activeTab?.length || activeTab === "text") && (
                <div className="flex flex-col gap-8 rounded-sm border border-[#D7443E33] bg-[#D7443E0A] -m-4 p-4">
                  <div className="flex items-center gap-8 font-semibold text-red">
                    <div className="text-[18px] font-black leading-3 text-red">
                      {errorTabs.text.icon}
                    </div>
                    Пропущен обязательный тип текста во включенном векторе
                  </div>
                  <div className="flex flex-col gap-4">
                    {textErrors?.map((el, index) => {
                      const vector = `${el.city_name} ${el.currency_from_name}-${el.currency_to_name}`;
                      return (
                        <div
                          key={`lang_${index}`}
                          className="flex flex-col gap-2 border border-[#BABAC333] rounded-sm py-2 px-4 -mx-4 bg-[#BABAC311] hover:bg-white duration-300">
                          <span>{vector}</span>
                          {el?.skippedTypes?.map((langEl) => (
                            <span
                              key={`${vector}_${langEl.lang}_type`}
                              className="flex items-center gap-4 w-fit rounded-sm bg-bg px-4 py-2 font-semibold whitespace-pre">
                              <span className="text-lightFont">{langEl.lang}:</span>
                              {langEl.types?.map((typeEl) => {
                                const currentSubType = typeEl?.name_en === TextType.MAIL 
                                  ? subTypes
                                    ?.find((el) => el.name === typeEl?.name_en)?.data
                                      ?.find(dataEl => dataEl?.text_subtype_name_en === TextSubType.EXCHANGE_REQUEST)
                                  : null
                                return (
                                  <button
                                    key={`${vector}_${langEl.lang}_${typeEl.name_ru}`}
                                    type="button"
                                    className="px-4 pb-2 rounded-sm bg-red text-[#FFF] hover:bg-pistachio duration-300"
                                    onClick={() => {
                                      putCity(el);
                                      setSelectedVectors([
                                        putVectorOption({
                                          vector: el,
                                          lang: langEl.lang,
                                        }),
                                      ]);
                                      setType(typeEl);
                                      setSubType(currentSubType);
                                      setLang({ label: langEl.lang, value: langEl.lang });
                                      setOpened(false);
                                      setIsCreate(true);
                                      searchParams.set("lang", langEl.lang);
                                      searchParams.set("type", typeEl?.id?.toString());
                                      searchParams.set("city", el?.city_id?.toString());
                                      if (!!currentSubType) {
                                        searchParams.set("subtype", currentSubType?.id?.toString());
                                      }
                                      setSearchParams(searchParams);
                                    }}>
                                    {typeEl.name_ru} {typeEl.name_en === TextType.MAIL ? `/ Создание заявки` : ""} 
                                  </button>
                                );
                              })}
                            </span>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {!!langErrors?.length && (!activeTab?.length || activeTab === "lang") && (
                <div className="flex flex-col gap-8 rounded-sm border border-[#FCC815AA] bg-[#FCC8150A] -m-4 p-4">
                  <div className="flex items-center gap-8 text-[#CCA805] font-semibold">
                    <div className="text-[18px] font-black leading-3 text-[#FCC815]">
                      {errorTabs.lang.icon}
                    </div>
                    Пропущен один из обнаруженных языков
                  </div>
                  <div className="flex flex-col gap-4">
                    {langErrors?.map((el, index) => {
                      const vector = `${el.city_name} ${el.currency_from_name}-${el.currency_to_name}`;
                      return (
                        <div key={`lang_${index}`}>
                          {vector}{" "}
                          {el?.skippedLanguages?.map((langEl) => (
                            <button
                              key={`${vector}_${langEl}`}
                              type="button"
                              className="rounded-sm bg-[#FCC815] text-[#FFF] px-4 mx-2 font-semibold duration-300 hover:bg-pistachio"
                              onClick={() => {
                                putCity(el);
                                setLang({ label: langEl, value: langEl });
                                setCurrencyType({
                                  currency_from_type: el?.currency_from_type,
                                  currency_to_type: el?.currency_to_type,
                                });
                                setSelectedVectors([
                                  putVectorOption({
                                    vector: el,
                                    lang: langEl,
                                  }),
                                ]);
                                setOpened(false);
                                setIsCreate(true);
                                searchParams.set("lang", langEl);
                                searchParams.set("city", el?.city_id?.toString());
                                setSearchParams(searchParams);
                              }}>
                              {langEl}
                            </button>
                          ))}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              {!!integratedVectors?.length && (!activeTab?.length || activeTab === "integrated") && (
                <div className="flex flex-col gap-8 rounded-sm border border-stale bg-[#BABAC30A] -m-4 p-4">
                  <div className="flex items-center gap-8 text-gray font-semibold">
                    <div className="text-[18px] font-black leading-3 text-[#BABAC3]">
                      {errorTabs.integrated.icon}
                    </div>
                    Невключенные на сайте векторы, по которым есть тексты
                  </div>
                  <div className="flex flex-col gap-4">
                    {integratedVectors?.map((el, index) => (
                      <div key={`integrated_${index}`}>
                        {el.city_name} {el.currency_from_name}-{el.currency_to_name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="rounded-sm bg-select px-8 py-4">
            Ошибок по включенным на сайте векторам не найдено
          </div>
        )}
      </div>
    );
  } else {
    return <></>;
  }
};
