import axios from "axios";
import { BASE_URL, LS, Url } from "./constants";
import Cookies from "universal-cookie";
import { store } from "../store/store";
import { setLogin, setLogout } from "../store/mainReducer";

const api = axios.create({
  baseURL: BASE_URL,
});

axios.defaults.withCredentials = true;

api.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  return config;
});

api.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    console.log(err);
    const originalReq = err.config;

    if (err.message === "Network Error" && !originalReq.url.includes("deals")) {
      throw new Error("Ошибка интернет соединения");
    }
    const status = err.response ? err.response.status : null;
    if ((status === 401 || status === 406) && err.config && !err.config._isRetry) {
      originalReq._isRetry = true;
      try {
        const cookies = new Cookies();
        const fingerprint = cookies.get("fingerprint");
        const response = await axios.patch(`${BASE_URL}/auth/refresh`, null, {
          headers: {
            fingerprint: fingerprint,
          },
        });
        if (response.status >= 200 && response.status < 300) {
          store.dispatch(setLogin());
          localStorage.setItem(LS.ACCESS, response?.data?.user_access_node?.join(", "));
          localStorage.setItem(LS.ROLE, response?.data?.user_role_name);
          return api.request(originalReq);
        }
        return api.request(originalReq);
      } catch (e) {
        if (e.response.status === 401 || e.response.status === 406 || e.response.status === 403) {
          localStorage.removeItem(LS.ACCESS);
          localStorage.removeItem(LS.ROLE);
          store.dispatch(setLogout());
          window.history.pushState({ replace: true }, "", Url.LOGIN);
        }
        return e;
      }
    }
    return err?.response || err;
  }
);

export default api;
