import { useEffect, useState } from "react";
import devApi from "src/api/developers";
import { fetchRequest } from "src/helpers/fetchRequest";
import { TimeEstimate } from "src/components/FormElements/TimeEstimate";
import { AddButton } from "src/components/UI/buttons/AddButton";
import { Countries } from "./Countries";
import { EProxyType, TCreateProxyPayload, TProxy, TSourceModel, TSourceVersion } from "../types";

export const BuyProxy = ({
  sources,
  pushProxies,
}: {
  sources: TSourceModel[];
  pushProxies: (arr: TProxy[]) => void;
}) => {
  const [isBuy, setIsBuy] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState<EProxyType>(EProxyType.HTTP);
  const [country, setCountry] = useState("ru");
  const [count, setCount] = useState(0);
  const [period, setPeriod] = useState(0);
  const [isChecked, setIsCheked] = useState<boolean | null>(null);
  const [source, setSource] = useState<TSourceModel>(sources[0]);
  const [price, setPrice] = useState(0);
  const [version, setVersion] = useState<TSourceVersion | null>(null);
  const versions = sources?.find((el) => el.id === source?.id)?.versions || [];
  const disabledCheck = count < 1 || period < 1 || !version || !country.length;
  const disabled = isLoading || disabledCheck || !isChecked;

  const checkAvailable = async () => {
    setLoading(true);
    const params = {
      count: count,
      period: period,
      country: country,
      proxy_version: version.version_name,
    };
    const { response, error } = await fetchRequest(devApi.getProxyAvailable(params), {
      request: "Проверка доступных прокси",
    });
    if (response) {
      setIsCheked(response.available === true);
      setPrice(response.price);
    }
    if (error) {
      setIsCheked(false);
    }
    setLoading(false);
  };

  const buyProxy = async () => {
    setLoading(true);
    const payload: TCreateProxyPayload = {
      count: count,
      period: period,
      country: country,
      proxy_version: version?.version_name,
      proxy_type: type,
    };
    const { response } = await fetchRequest(devApi.buyProxy(payload), {
      request: "Покупка прокси",
    });
    if (response) {
      pushProxies(response);
      setIsBuy(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    setIsCheked(null);
    setPrice(0);
  }, [count, period, country]);

  useEffect(() => {
    const defaultVersions = sources[0]?.versions || [];
    if (!!defaultVersions?.length) {
      setVersion(defaultVersions?.[defaultVersions?.length - 1]);
    }
  }, [source?.id]);

  return (
    <div className="w-full flex flex-col mt-[20px]">
      <AddButton title="Купить прокси" onClick={() => setIsBuy(!isBuy)} />
      <div
        className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
          isBuy ? "max-h-[1500px] opacity-100 my-8 py-[12px]" : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
        }`}>
        setCount
        <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
          <div className="text-right">count</div>
          <input
            className={`border border-[#EEE] outline-lightGray rounded-sm px-[8px]`}
            type="number"
            value={count}
            step={1}
            min={0}
            onChange={(e) => {
              setCount(+e.target.value);
            }}
          />
        </div>
        <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
          <div className="text-right">period</div>
          <div className="w-fit">
            <TimeEstimate value={period} setValue={setPeriod} isAccess isDays />
          </div>
        </div>
        {isBuy && !!version?.version_name && (
          <Countries
            versionName={version?.version_name}
            country={country}
            setCountry={setCountry}
          />
        )}
        <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
          <div className="text-right">source</div>
          <div className="flex gap-4">
            {sources?.map((el) => (
              <button
                key={el.name}
                type="button"
                onClick={() => setSource(el)}
                className={`rounded-sm text-[#FFF] text-[10px] px-4 font-medium h-[15px] ${
                  source?.name === el.name ? "bg-[#FCC815]" : "bg-[#BABAC3]"
                }`}>
                {el.name}
              </button>
            ))}
          </div>
        </div>
        <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
          <div className="text-right">version</div>
          <div className="flex flex-col gap-4">
            {versions?.map((el) => (
              <button
                key={el?.version_name}
                type="button"
                onClick={() => setVersion(el)}
                className={`w-fit rounded-sm text-[#FFF] text-[10px] px-4 font-medium h-[15px] ${
                  version?.version_name === el?.version_name ? "bg-[#FCC815]" : "bg-[#BABAC3]"
                }`}>
                <b>{el?.version_name}</b> ({el.comment})
              </button>
            ))}
          </div>
        </div>
        <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
          <div className="text-right">proxy_type</div>
          <div className="flex gap-4">
            {Object.entries(EProxyType)?.map((el) => (
              <button
                key={el[1]}
                type="button"
                onClick={() => setType(el[1])}
                className={`rounded-sm text-[#FFF] text-[10px] px-4 font-medium h-[15px] ${
                  type === el[1] ? "bg-[#FCC815]" : "bg-[#BABAC3]"
                }`}>
                {el[1]}
              </button>
            ))}
          </div>
        </div>
        {price > 0 && (
          <div className={`grid grid-cols-[66px_1fr] items-baseline gap-[10px]`}>
            <div className="text-right">price</div>
            <div className="w-fit rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] px-4 font-medium h-[15px]">
              {price}
            </div>
          </div>
        )}
        <div className="flex gap-8 self-end mt-8">
          <button
            className={`px-[16px] border font-semibold rounded-sm duration-300 ${
              disabledCheck ? "opacity-30" : ""
            } ${
              isChecked
                ? "bg-[#BBFFDA] text-[#3BC57A] border-[#3BC57A]"
                : isChecked === false
                ? "bg-[#D7443E11] border-red text-red"
                : "bg-light border-lightGray text-lightFont"
            }`}
            disabled={disabledCheck}
            onClick={() => checkAvailable()}>
            {isChecked
              ? "✓ Проверено"
              : isChecked === false
              ? "Проверка не прошла"
              : "Проверить количество"}
          </button>
          <button
            className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              disabled ? "opacity-30" : ""
            }`}
            disabled={disabled}
            onClick={() => buyProxy()}>
            Купить
          </button>
        </div>
      </div>
    </div>
  );
};
