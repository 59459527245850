import { TRates } from "src/store/statsReducer";

export enum ERateSource {
  OUR_COURSE = "OUR_COURSE",
  BINANCE = "BINANCE",
  TRADING_VIEW = "TRADING_VIEW",
  MOEX = "MOEX",
}

export const sourceColor = (source: string) => {
  switch (source) {
    case ERateSource.BINANCE:
      return "#FFBC0A";
    case ERateSource.TRADING_VIEW:
      return "#526889";
    case ERateSource.OUR_COURSE:
      return "#52D58E";
    case ERateSource.MOEX:
      return "#A58";
    default:
      return "#89A";
  }
};

export const findBinance = (arr: TRates[]): number => {
  const binance_rate = arr?.find((el) => el.sources?.includes(ERateSource.BINANCE))?.course;
  if (!!binance_rate) {
    return +binance_rate;
  } else {
    return +arr[0]?.course;
  }
};

export const findPreferSourse = (arr: TRates[], isDoubleUSD: boolean): number => {
  if (isDoubleUSD) {
    const our_rate = arr?.find((el) => el.sources?.includes(ERateSource.OUR_COURSE))?.course;
    if (!!our_rate) {
      return +our_rate;
    } else {
      return findBinance(arr);
    }
  } else {
    return findBinance(arr);
  }
};

export const findCurrentRate = ({
  rates,
  curFrom,
  curTo,
}: {
  rates: TRates[];
  curFrom: string;
  curTo: string;
}): number | null => {
  const from = curFrom?.includes("RUB") ? "CASHRUB" : curFrom;
  const to = curTo?.includes("RUB") ? "CASHRUB" : curTo;

  const isDoubleUSD = curFrom?.includes("USD") && to?.includes("USD");
  const isDoubleRub = curFrom?.includes("RUB") && to?.includes("RUB");

  if (isDoubleRub) {
    return 1;
  }

  const currentArr = rates?.filter((el) =>
    from?.includes("CASH")
      ? el?.name?.replace("20", "") === `${to} - ${from?.replace("CASH", "")}`
      : el?.name?.replace("20", "") === `${from} - ${to?.replace("CASH", "")}`
  );
  let currentRate: number | null = null;

  if (!!currentArr?.length) {
    currentRate = findPreferSourse(currentArr, isDoubleUSD);
  } else {
    const arr = rates?.filter(
      (el) =>
        el?.name?.replace("20", "") === `${from?.replace("CASH", "")} - ${to?.replace("CASH", "")}`
    );
    if (!!arr?.length) {
      currentRate = findPreferSourse(arr, isDoubleUSD);
    } else {
      return null;
    }
  }

  return currentRate || null;
};
