import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSelect, { createFilter } from "react-select";
import {
  selectRates,
  setRates,
  setCountries,
  selectCountries,
  selectStaticCountries,
  setStaticCountries,
} from "src/store/bidReducer";
import {
  selectCurFrom,
  selectCurTo,
  setSelectedFrom,
  setSelectedTo,
} from "src/store/directionsReducer";
import { setNotific } from "src/store/mainReducer";
import { useForm, FormProvider, FieldValues } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import bidApi from "src/api/bid";
import { LS } from "src/api/constants";
import "src/components/UI/switcher/switcher.scss";
import { Redactor } from "src/components/Redactors/Redactor";
import { Spinner } from "src/components/Spinner/Spinner";
import { UpdateButton } from "src/components/UI/buttons/UpdateButton";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import { CountryRow } from "./components/CountryRow";
import { TVectorSelect } from "./models";

export const Bid = () => {
  const dispatch = useDispatch();
  const methods = useForm({ mode: "onChange" });
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const access = userAccess?.includes("bid_post") as boolean;
  const [searchParams, setSearchParams] = useSearchParams();
  const [isGood, setIsGood] = useState<boolean>(true);
  const [redactorDisabled, setRedactorDisabled] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentVector, setCurrentVector] = useState<TVectorSelect | null>(null);
  const curFrom = useSelector(selectCurFrom);
  const curTo = useSelector(selectCurTo);
  const rates = useSelector(selectRates);
  const vectors = useMemo(
    () =>
      rates?.map((el) => ({
        label: `${el.currency_from}-${el.currency_to}`,
        value: { currency_from: el.currency_from, currency_to: el.currency_to },
      })),
    [rates]
  );
  const countries = useSelector(selectCountries);
  const staticCountries = useSelector(selectStaticCountries);

  const fetchBidData = async () => {
    const { response: responseRates, error } = await fetchRequest(bidApi.getRates(), {
      request: "Ставки",
    });

    if (responseRates) {
      dispatch(setRates(responseRates));
      setIsGood(true);
    }
    if (error) {
      dispatch(setRates([]));
      setIsGood(false);
    }
  };

  const onUpdate = async () => {
    await fetchBidData();
  };

  useEffect(() => {
    const vector = searchParams?.get("vector");
    if (!!vector && !!vectors?.length) {
      const vectorData = vector?.split("-");
      const isCrypto = vectorData[0] === "CRYPTO";
      setCurrentVector(vectors?.find((el) => el.label === vector));
      isCrypto &&
        (curFrom === vectorData[1]
          ? dispatch(setSelectedTo(null))
          : dispatch(setSelectedTo(vectorData[1])));
      !isCrypto && dispatch(setSelectedFrom(vectorData[0]));
    }
    if (!vector && !!vectors?.length && (!!curFrom || !!curTo)) {
      if (!!curFrom && curFrom?.includes("CASH")) {
        setCurrentVector(vectors?.find((el) => el.label?.startsWith(curFrom)));
      }
      if (!!curTo && curTo?.includes("CASH")) {
        setCurrentVector(vectors?.find((el) => el.label?.includes(`-${curTo}`)));
      }
    }
  }, [vectors]);

  useEffect(() => {
    (async () => {
      await fetchBidData();
    })();
    const timerBid = setInterval(() => {
      fetchBidData();
    }, 10000);
    return () => clearInterval(timerBid);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (rates.length) {
        const findCountries = rates?.find(
          (item) =>
            item?.currency_from === currentVector?.value?.currency_from &&
            item?.currency_to === currentVector?.value?.currency_to
        )?.data;
        if (findCountries) {
          dispatch(setCountries(findCountries));
          dispatch(setStaticCountries(findCountries));
          setLoading(false);
        }
        setLoading(false);
      }
      setLoading(false);
    })();
  }, [currentVector, rates]);

  const onSubmit = async (e: FieldValues) => {
    const countriesData = countries?.map((el) => ({
      ...el,
      boss_rate: e[el.country],
      cities_data: el.cities_data?.map((city) => ({
        ...city,
        rate: e[city.city_id]?.rate,
        is_delta: e[city.city_id]?.is_delta,
      })),
    }));

    const data = [
      {
        currency_from: currentVector?.value?.currency_from,
        currency_to: currentVector?.value?.currency_to,
        data: countriesData,
      },
    ];

    const { response } = await fetchRequest(bidApi.sendForm(data), {
      request: "Обновление ставок",
    });
    if (response) {
      const { response: responseRates } = await fetchRequest(bidApi.getRates());
      dispatch(
        setNotific({
          type: "success",
          message: "Ставки успешно обновлены",
          request: "Обновление ставок",
        })
      );
      dispatch(setRates(responseRates));
    }
  };

  return (
    <div>
      <div className="flex flex-col justify-center relative w-[calc(100% - 100px)] mr-[30px]">
        <div className="p-6 w-[290px] lg:w-[480px] table rounded-xl shadow-lg items-center space-x-4 w-full">
          <FormProvider {...methods}>
            <ReactSelect
              placeholder={"Направление..."}
              className="text font-bold"
              classNamePrefix={"Select"}
              value={currentVector}
              onChange={(vector: TVectorSelect) => {
                searchParams.set("vector", vector?.label);
                setSearchParams(searchParams);
                setCurrentVector(vector);
                const vectorData = vector?.label?.split("-");
                const isCrypto = vectorData[0] === "CRYPTO";
                !isCrypto && dispatch(setSelectedFrom(vectorData[0]));
                isCrypto &&
                  (!!curFrom ? curFrom !== vectorData[1] : true) &&
                  dispatch(setSelectedTo(vectorData[1]));
              }}
              options={vectors}
              filterOption={createFilter(selectFilter)}
            />
            {isLoading && (
              <div className="absolute w-full top-[150px] flex justify-center">
                <Spinner />
              </div>
            )}
            {!!countries?.length && !!currentVector && (
              <form style={{ marginLeft: "0px" }} onSubmit={methods.handleSubmit(onSubmit)}>
                <div className={"flex"}>
                  <div className="shadow-md rounded-lg mt-[20px] w-full">
                    <div className={`hidden table-header sm:grid grid-cols-2 pl-[20px] pr-[70px]`}>
                      <div className="text-left">Город</div>
                      <div>Ставка/Дельта</div>
                    </div>
                    {!!currentVector &&
                      countries?.map((item) => (
                        <CountryRow
                          key={item.country}
                          item={item}
                          staticCountries={staticCountries}
                          redactorDisabled={redactorDisabled}
                          access={access}
                          isLoading={isLoading}
                        />
                      ))}
                  </div>
                </div>
                {userAccess?.includes("bid_post") && !redactorDisabled && (
                  <button className="prime-button mt-[20px]">Отправить</button>
                )}
              </form>
            )}
          </FormProvider>
        </div>
        {userAccess?.includes("bid_post") &&
          !!currentVector &&
          currentVector?.label?.length > 0 && (
            <Redactor
              currentVector={currentVector}
              setRedactorDisabled={setRedactorDisabled}
              page="bid"
            />
          )}
        {!!currentVector && currentVector?.label?.length > 0 && (
          <div className={`fixed top-[132px] left-[20px]`}>
            <UpdateButton updFn={onUpdate} time={10} dependence={countries} />
          </div>
        )}
        {!isGood && !countries?.length && <Emptiness message={"Ошибка сервиса"} />}
        <div className="h-[100px]" />
      </div>
    </div>
  );
};
