import { CityRow } from "./CityRow";
import { useFormContext } from "react-hook-form";
import { ICountryData } from "../models";

type TProps = {
  item: ICountryData;
  staticCountries: Array<ICountryData>;
  isRegion: boolean;
  isOpen: boolean;
  access: boolean;
  redactorDisabled: boolean;
  isLoading: boolean;
};

export const SectorRow = ({
  item,
  isRegion,
  isOpen,
  access,
  redactorDisabled,
  staticCountries,
  isLoading,
}: TProps) => {
  const { setValue } = useFormContext();
  const items = item?.cities_data?.filter((city) => city?.is_region === isRegion);

  const setAll = ({ rate, isDelta }: { rate: number; isDelta: boolean }) => {
    items.map((el) => {
      setValue(`${el?.city_id}.rate`, rate);
      setValue(`${el?.city_id}.is_delta`, isDelta);
    });
  };

  return (
    <div
      className={`duration-700 overflow-y-auto ${
        isOpen ? `max-h-[430px] ${!!items.length && "pb-[12px]"}` : "max-h-0 pb-0"
      }`}>
      {item?.cities_data
        ?.filter((city) => city?.is_region === true)
        ?.map((el, index) => (
          <CityRow
            item={el}
            boss={item.boss_rate}
            index={index}
            regionLength={items.length}
            key={`${item.country}-${el.city_id}`}
            access={access}
            country={item.country}
            staticCountries={staticCountries}
            redactorDisabled={redactorDisabled}
            setAll={setAll}
            isRegion={isRegion}
            isLoading={isLoading}
          />
        ))}
      {item?.cities_data
        ?.filter((city) => city?.is_region === false)
        ?.map((el, index) => (
          <CityRow
            item={el}
            boss={item.boss_rate}
            index={index}
            regionLength={items.length}
            key={`${item.country}-${el.city_id}`}
            access={access}
            country={item.country}
            staticCountries={staticCountries}
            redactorDisabled={redactorDisabled}
            setAll={setAll}
            isRegion={isRegion}
            isLoading={isLoading}
          />
        ))}
    </div>
  );
};
