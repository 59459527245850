import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TRates, setFavorites } from "src/store/statsReducer";
import { LS } from "src/api/constants";
import { sourceColor } from "src/helpers/tetrisHelper";
import { MiniSpinner } from "src/components/Spinner/MiniSpinner";
import { TimeStamp } from "src/components/UI/timestamp/TimeStamp";
import ok from "src/assets/images/check.svg";

export const RateRow = ({
  item,
  favorites,
  removeItem,
}: {
  item: TRates;
  favorites: string[];
  removeItem: (item: string) => void;
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const nameStr = item?.name?.split(" - ");
  const disabled = favorites?.length > 5;

  const switchItem = (item: string) => {
    setIsLoading(true);
    if (favorites?.includes(item)) {
      removeItem(item);
    } else {
      if (favorites?.length) {
        dispatch(setFavorites([...favorites, item]));
        localStorage.setItem(LS.FAVORITES, [...favorites, item]?.join(","));
      } else {
        dispatch(setFavorites([item]));
        localStorage.setItem(LS.FAVORITES, item);
      }
    }
  };

  useEffect(() => {
    setIsLoading(false);
  }, [favorites]);

  return (
    <button
      onClick={() => switchItem(`${item?.name} - ${item.sources[0]}`)}
      disabled={disabled}
      className="text-xs md:text-sm flex flex-wrap md:flex-nowrap w-[300px] md:w-[600px] md:mr-[50px] items-center rounded-[13px] border border-bg hover:border-select pl-8 hover:bg-glass-select disabled:bg-bg disabled:hover:bg-glass-gray disabled:hover:border-bg">
      <div className="flex w-[160px] md:w-[190px] 2xl:w-[210px] items-center">
        {isLoading ? (
          <div className="max-h-[20px] max-w-[20px] relative left-[-5px]">
            <MiniSpinner />
          </div>
        ) : (
          <span
            className={`relative rounded-[50%] left-[-5px] hover:opacity-30 hover:grayscale-0 ${
              favorites?.includes(`${item?.name} - ${item.sources[0]}`)
                ? ""
                : "grayscale opacity-30"
            } ${disabled ? "grayscale-50 opacity-30" : ""}`}>
            <img
              src={ok}
              alt="выключить"
              width={16}
              height={16}
              className="min-w-[16px] h-[16px]"
            />
          </span>
        )}
        <div className="font-bold md:ml-[10px]">{nameStr[0]?.replace("20", "")}</div>
        <div className="text-sm text-lightGray mx-4">{`\u279C`}</div>
        <div className="font-bold">{nameStr[1]?.replace("20", "")}</div>
      </div>
      <div
        className={
          "text-[10px] md:text-[12px] text-left w-[85px] md:w-[110px] xl:w-[150px] 2xl:w-[170px]"
        }>
        {item?.course}
      </div>
      <TimeStamp item={item} />
      {item?.sources?.map((el) => (
        <div
          key={el}
          className={`badge text-[9px] leading-3 py-[1px] px-[5px] mb-0`}
          style={{ backgroundColor: sourceColor(el) }}>
          {el}
        </div>
      ))}
    </button>
  );
};
