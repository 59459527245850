import { useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { LS } from "src/api/constants";
import { rateApi } from "src/api/prof";
import { Emptiness } from "src/components/UI/loader/Emptiness";
import Table from "./components/table";
import update from "src/assets/images/update.svg";

export const ProfForm = () => {
  const userAccess = localStorage.getItem(LS.ACCESS)?.split(", ");
  const [mainData, setData] = useState([]);
  const [historyData, setHistoryData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isGood, setIsGood] = useState<boolean>(true);
  const [notif, setNotif] = useState("");

  const fetchData = async () => {
    setIsLoading(true);
    await rateApi
      .getProfRate()
      ?.then(async (data) => {
        setData(data);
        await rateApi
          .getProfLastHistory()
          ?.then((data2) => {
            setHistoryData(data2);
            setIsLoading(false);
          })
          .catch(() => {
            setIsGood(false);
          });
      })
      .catch(() => {
        setIsGood(false);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const submitHandler = async (e: FieldValues) => {
    e.preventDefault();

    await rateApi.postProfRate(mainData)?.then(() => {
      setNotif("сообщение успешно отправлено!");
      fetchData();
    });

    setTimeout(() => {
      setNotif("");
    }, 4000);
  };

  return (
    <div className="flex flex-col justify-center relative w-[calc(100% - 100px)] mr-[30px] mt-[20px] pb-[100px]">
      <form onSubmit={submitHandler}>
        {!isLoading &&
          mainData.length > 0 &&
          mainData?.map((item) => {
            const detectedHistory = historyData?.find(
              (el) => el.city_name === item?.city_name
            )?.rates;
            item.history_rates = detectedHistory ? detectedHistory : 0;
            return (
              <Table
                key={item?.city_name}
                item={item}
                mainData={mainData}
                setData={setData}
                isAccess={userAccess?.includes("bid_post")}
              />
            );
          })}

        {isLoading && <p>Загружаем данные...</p>}
        {userAccess?.includes("bid_post") && !isLoading && !!mainData?.length && (
          <button className="prime-button mt-[20px]">Отправить</button>
        )}
        {!isLoading && (
          <div className={`fixed top-[60px] lg:top-[96px] left-[10px] lg:left-[26px]`}>
            <button
              onClick={fetchData}
              className={`rounded-full p-2 bg-bg shadow-inner hover:shadow-smooth`}>
              <img src={update} alt="Обновить" width={40} height={40} />
            </button>
          </div>
        )}
        <p>{notif}</p>
      </form>
      {!isGood && !mainData?.length && (
        <div className="w-full h-full relative top-[80px] mx-auto">
          <Emptiness message={"Ошибка сервиса"} />
        </div>
      )}
    </div>
  );
};
