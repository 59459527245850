import { useEffect, useState } from "react";

const Table = ({ item, setData, mainData, isAccess }) => {
  const [UsdtLocal, setUsdtLocal] = useState(0);
  const [LocalUsdt, setLocalUsdt] = useState(0);
  const [UsdtUsd, setUsdtUsd] = useState(0);
  const [UsdUsdt, setUsdUsdt] = useState(0);
  const [TryUsdt, setTryUsdt] = useState(0);
  const [UsdtTry, setUsdtTry] = useState(0);
  const [AmdUsdt, setAmdUsdt] = useState(0);
  const [UsdtAmd, setUsdtAmd] = useState(0);
  const [EurUsdt, setEurUsdt] = useState(0);
  const [UsdtEur, setUsdtEur] = useState(0);

  const [itm, setItem] = useState(
    item.rates?.map((el) => ({
      ...el,
      comment:
        item?.history_rates && Array.isArray(item?.history_rates)
          ? item?.history_rates?.find(
              (historyEl) =>
                `${historyEl.currency_from}-${historyEl.currency_to}` ===
                `${el.currency_from}-${el.currency_to}`
            )?.comment || ""
          : "",
    }))
  );

  const countValue = (asPercent, percent, action, value) => {
    if (action === "buy" && +percent !== null) {
      if (!asPercent) {
        const val = +value + (+value * +percent) / 100;
        return val ? +val.toFixed(3) : 0;
      } else {
        const val = +value + +percent;
        return val ? +val.toFixed(3) : 0;
      }
    } else if (action === "sell" && +percent) {
      if (!asPercent) {
        const val = +value - (+value * +percent) / 100;
        return val ? +val.toFixed(3) : 0;
      } else {
        const val = +value - +percent;
        return val ? +val.toFixed(3) : 0;
      }
    } else {
      return value ? +value.toFixed(3) : 0;
    }
  };

  useEffect(() => {
    const newItem = [...itm];
    newItem?.map((el) => {
      if (el.currency_from === "USD" && el.currency_to === "USDT") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdUsdt);
      } else if (el.currency_from === "TRY" && el.currency_to === "USDT") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, TryUsdt);
      } else if (el.currency_from === "USDT" && el.currency_to === "TRY") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdtTry);
      } else if (el.currency_from === "AMD" && el.currency_to === "USDT") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, AmdUsdt);
      } else if (el.currency_from === "USDT" && el.currency_to === "AMD") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdtAmd);
      } else if (el.currency_from === "EUR" && el.currency_to === "USDT") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, EurUsdt);
      } else if (el.currency_from === "USDT" && el.currency_to === "EUR") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdtEur);
      } else if (el.currency_from === "USDT" && el.currency_to === "USD") {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdtUsd);
      } else if (
        el.currency_from === "AED" ||
        (el.currency_from === "RUB" && el.currency_to === "USDT")
      ) {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, LocalUsdt);
      } else if (
        el.currency_to === "AED" ||
        (el.currency_to === "RUB" && el.currency_from === "USDT")
      ) {
        el.rate = countValue(el.as_percentage, el.percent, el.deal_type, UsdtLocal);
      }
      return el;
    });
    setItem(newItem);
  }, [
    UsdUsdt,
    LocalUsdt,
    UsdtUsd,
    UsdtLocal,
    UsdtTry,
    TryUsdt,
    UsdtAmd,
    UsdtEur,
    EurUsdt,
    AmdUsdt,
  ]);

  useEffect(() => {
    if (!!mainData?.length) {
      setData([
        ...mainData.map((el) => {
          if (el.city_name === item.city_name) {
            el.rates = [...itm];
          }
          return el;
        }),
      ]);
    }
  }, [itm]);

  const defineValue = (to: string, from: string) => {
    if (to === "AED" || (to === "RUB" && from === "USDT")) {
      return UsdtLocal;
    }
    if (from === "AED" || (from === "RUB" && to === "USDT")) {
      return LocalUsdt;
    }
    if (from === "TRY" && to === "USDT") {
      return TryUsdt;
    }
    if (to === "TRY" && from === "USDT") {
      return UsdtTry;
    }
    if (from === "USDT" && to === "USD") {
      return UsdtUsd;
    }
    if (from === "USD" && to === "USDT") {
      return UsdUsdt;
    }
    if (from === "AMD" && to === "USDT") {
      return AmdUsdt;
    }
    if (from === "EUR" && to === "USDT") {
      return EurUsdt;
    }
    if (from === "USDT" && to === "AMD") {
      return UsdtAmd;
    }
    if (from === "USDT" && to === "EUR") {
      return UsdtEur;
    }
  };

  const changeComment = (nameTo: string, nameFrom: string, value: string) => {
    const newItem = [...itm];
    newItem?.map((el) => {
      if (el.currency_to === nameTo && el.currency_from === nameFrom) {
        el.comment = value;
      }
      return el;
    });
    setItem(newItem);
  };

  const defineAction = (to: string, from: string, target: number) => {
    if (to === "AED" || (to === "RUB" && from === "USDT")) {
      return setUsdtLocal(target);
    }
    if (from === "AED" || (from === "RUB" && to === "USDT")) {
      return setLocalUsdt(target);
    }
    if (from === "USDT" && to === "TRY") {
      return setUsdtTry(target);
    }
    if (from === "TRY" && to === "USDT") {
      return setTryUsdt(target);
    }
    if (from === "USDT" && to === "USD") {
      return setUsdtUsd(target);
    }
    if (from === "USD" && to === "USDT") {
      return setUsdUsdt(target);
    }
    if (from === "AMD" && to === "USDT") {
      return setAmdUsdt(target);
    }
    if (from === "EUR" && to === "USDT") {
      return setEurUsdt(target);
    }
    if (from === "USDT" && to === "AMD") {
      return setUsdtAmd(target);
    }
    if (from === "USDT" && to === "EUR") {
      return setUsdtEur(target);
    }
  };

  useEffect(() => {
    if (Array.isArray(item?.history_rates)) {
      item?.history_rates?.forEach((el) => {
        defineAction(el?.currency_to, el?.currency_from, el?.origin_rate);
      });
    }
  }, []);

  return (
    <div className="table mx-auto w-fit mb-8 p-[10px] sm:p-0">
      <div className="hidden table-header sm:grid grid-cols-[130px_130px_130px_180px] px-[30px]">
        <div>{item.city_name}</div>
        <div>Проф курс</div>
        <div>В телегу</div>
        <div>Комментарий</div>
      </div>
      <div className="flex sm:hidden font-semibold">{item.city_name}</div>
      <div>
        {itm?.map((el, index) => {
          return (
            <div
              key={`${index}_${item}_${el.currency_to}_${el.currency_from}`}
              className="grid grid-cols-[100px_100px_100px] sm:grid-cols-[130px_130px_130px_180px] items-center justify-around sm:px-[20px] py-[3px] text-xs font-light">
              <div className={"text-left sm:text-center"}>
                {el.currency_from} - {el.currency_to}
              </div>
              <div>
                {isAccess ? (
                  <input
                    className="max-w-[100px] sm:max-w-[120px] px-4 border-lightGray border-[1px] my-4 rounded-lg outline-none"
                    type="number"
                    step="any"
                    value={defineValue(el.currency_to, el.currency_from)}
                    onChange={({ target }) =>
                      defineAction(el.currency_to, el.currency_from, +target.value)
                    }
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />
                ) : (
                  <div>{defineValue(el.currency_to, el.currency_from)}</div>
                )}
              </div>
              <div>
                {el.percent !== null && (
                  <div className="max-w-[100px] sm:max-w-[120px]">
                    {countValue(
                      el.as_percentage,
                      el.percent,
                      el.deal_type,
                      defineValue(el.currency_to, el.currency_from)
                    )}
                  </div>
                )}
              </div>
              <textarea
                className="px-4 col-span-3 sm:col-span-1 border-lightGray border-[1px] my-4 rounded-lg outline-none"
                value={el.comment ? el.comment : ""}
                disabled={!isAccess}
                onChange={({ target }) =>
                  changeComment(el.currency_to, el.currency_from, target.value)
                }
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Table;
