import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import ReactSelect, { createFilter } from "react-select";
import { fetchRequest } from "src/helpers/fetchRequest";
import { convertToOptions, selectFilter } from "src/helpers/formHelpers";
import { TSelect } from "src/store/directionsReducer";
import devApi from "src/api/developers";
import { setNotific } from "src/store/mainReducer";
import { Field } from "src/components/FormElements/Field";
import { DateField } from "src/components/FormElements/DateField";
import { TBaseModel, TProxy, TSourceModel } from "../types";
import { Prolong } from "./Prolong";

type TProps = {
  item: TProxy;
  source?: TSelect;
  sources: TSourceModel[];
  sourcesOptions: Array<TSelect>;
  servicesOptions: Array<TSelect>;
  targets: Array<TBaseModel>;
  fetchProxy: () => Promise<void>;
  updateItem: (item: TProxy) => void;
};

export const ProxyForm = ({
  item,
  source,
  sources,
  sourcesOptions,
  servicesOptions,
  targets,
  fetchProxy,
  updateItem,
}: TProps) => {
  const dispatch = useDispatch();
  const methods = useForm();
  const [selectedServices, setSelectedServices] = useState<Array<number>>(item?.reserved_by);
  const [selectedSource, setSelectedSource] = useState<number>(item?.source_id);
  const [date, setDate] = useState<string>(item?.exp);
  const [version, setVersion] = useState<string | null>(item.proxy_version || null);
  const [isLoading, setLoading] = useState(false);
  const dirtyFields = Object.keys(methods.formState.dirtyFields);
  const errors = Object.keys(methods.formState.errors);
  const currentSourse = sources?.find((el) => el.id === item.source_id);
  const versionsOptions = convertToOptions(
    currentSourse?.versions?.map((el) => el.version_name) || []
  );
  const currentVersion = !!item.proxy_version
    ? currentSourse?.versions?.find((el) => el.version_name === item.proxy_version)
    : null;

  const disabled =
    isLoading ||
    !!errors.length ||
    (!dirtyFields?.map((el) => methods.watch(el) === item[el].toString()).includes(false) &&
      JSON.stringify(selectedServices) === JSON.stringify(item?.reserved_by) &&
      version === item.proxy_version &&
      selectedSource === item?.source_id &&
      date === item?.exp);

  const updateProxy = async (e) => {
    const payload = [
      {
        ...item,
        ...e,
        port: +e.port,
        exp: date,
        reserved_by: selectedServices || [],
        source_id: selectedSource,
        proxy_version: version,
      },
    ];
    const { response } = await fetchRequest(devApi.updateProxy(payload), {
      request: "Обновление прокси",
    });
    if (response) {
      dispatch(
        setNotific({ type: "success", message: "Успешно обновлено", request: "Обновление прокси" })
      );
      await fetchProxy();
    }
  };

  useEffect(() => {
    !!item.reserved_by && setSelectedServices(item.reserved_by);
    !!item.source_id && setSelectedSource(item.source_id);
    !!item.exp && setDate(item.exp);
    !!item.proxy_version && setVersion(item.proxy_version);
  }, [item]);

  return (
    <FormProvider {...methods}>
      <form
        className="pt-[12px] pr-[6px] flex flex-col"
        onSubmit={methods.handleSubmit(updateProxy)}>
        <div className="grid sm:grid-cols-[250px_1fr] gap-[10px]">
          <div className="flex flex-col gap-4 w-fit w-[250px]">
            <Field title="user" name="user" defaultValue={item?.user} required />
            <Field title="password" name="password" defaultValue={item?.password} required />
            <Field title="host" name="host" defaultValue={item?.host} required />
            <Field
              title="port"
              name="port"
              defaultValue={item?.port}
              required
              isNumber
              isPositive
            />
            <DateField title="expired" value={date} setValue={setDate} />
          </div>
          <div className="flex flex-col justify-between gap-4">
            <div className="flex flex-col gap-4">
              <div className="grid grid-cols-[60px_1fr] items-baseline gap-[10px]">
                <div className="relative -top-4">Сервисы:</div>
                <div className="flex flex-col gap-4">
                  <ReactSelect
                    classNamePrefix="SquareSelect"
                    options={servicesOptions?.filter((el) => !selectedServices?.includes(el.value))}
                    filterOption={createFilter(selectFilter)}
                    placeholder="Добавьте сервис"
                    onChange={(e: TSelect) => setSelectedServices([...selectedServices, e.value])}
                  />
                  <div className="flex flex-wrap gap-2">
                    {selectedServices?.map((el) => (
                      <div
                        key={`service${el}`}
                        className="whitespace-pre w-fit rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] pl-4 pr-8 font-medium h-[15px] flex gap-4 items-center">
                        {servicesOptions?.find((service) => service.value === el)?.label}
                        <button
                          type="button"
                          className="relative left-2 rotate-45 text-[12px]"
                          onClick={() => {
                            setSelectedServices(
                              selectedServices?.filter((service) => service !== el)
                            );
                          }}>
                          +
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-[60px_1fr] items-center gap-[10px]">
                <div>Источник:</div>
                <ReactSelect
                  classNamePrefix="SquareSelect"
                  options={sourcesOptions.filter((el) => selectedSource !== el.value)}
                  filterOption={createFilter(selectFilter)}
                  defaultValue={source}
                  onChange={(e: TSelect) => setSelectedSource(e.value)}
                />
              </div>
              {!!item?.problems.length && (
                <div className="grid grid-cols-[60px_1fr] items-baseline gap-[10px]">
                  <div>Забанено:</div>
                  <div className="flex flex-wrap gap-2">
                    {item?.problems?.map((el) => (
                      <div
                        key={`target${el}`}
                        className="whitespace-pre w-fit rounded-sm bg-[#E7544E] text-[#FFD4CE] text-[10px] px-4 font-medium h-[15px]">
                        {targets?.find((target) => target.id === el)?.name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className={`grid grid-cols-[60px_1fr] items-baseline gap-[10px]`}>
              <div>version:</div>
              {!!item.proxy_version ? (
                <div className="w-fit">{item.proxy_version}</div>
              ) : (
                <ReactSelect
                  classNamePrefix="SquareSelect"
                  options={versionsOptions}
                  filterOption={createFilter(selectFilter)}
                  onChange={(e: TSelect) => setVersion(e.label)}
                />
              )}
            </div>
            {!!currentVersion && (
              <Prolong
                item={item}
                updateItem={updateItem}
                version={currentVersion}
                isLoading={isLoading}
                setLoading={setLoading}
              />
            )}
            <button
              className={`prime-button-sm bg-font text-xs mb-0 self-end duration-300 ${
                disabled ? "opacity-30" : ""
              }`}
              disabled={disabled}>
              Обновить
            </button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
