import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { createRatesSubscription } from "src/api/centrifugo/ratesCentrifugo";
import { createRequestsSubscription } from "../../api/centrifugo/requestsCentrifugo";
import {
  createAlertsSubscription,
  createFlightsSubscription,
} from "src/api/centrifugo/statsCentrifugo";
import { selectCentrifugoConnection } from "src/store/mainReducer";
import {
  selectFavorites,
  selectFlights,
  selectRates,
  selectReviews,
  setFavorites,
} from "src/store/statsReducer";
import { TAlert } from "src/pages/Stats/Stats";
import { LS, Url } from "src/api/constants";
import { OFFSET } from "src/helpers/date";
import { notify } from "../SystemAlerts/SystemAlerts";
import { SideBarSection } from "./components/SideBarSection";
import review from "src/assets/images/review.svg";
import alert from "src/assets/images/alert.svg";
import flight from "src/assets/images/flight.svg";
import chart from "src/assets/images/chart.svg";

export const SideBar = ({ alerts }: { alerts?: Array<TAlert> }) => {
  const dispatch = useDispatch();
  const page = useLocation().pathname;
  const isCentrifugoConnection = useSelector(selectCentrifugoConnection);
  const [isRatesFull, setIsRatesFull] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [blockRatesAlert, setBlockRatesAlert] = useState(false);
  const reviews = useSelector(selectReviews);
  const flights = useSelector(selectFlights);
  const allRates = useSelector(selectRates);
  const rates = useSelector(selectFavorites);
  const ratesPadding = !!rates?.length && isRatesFull ? rates?.length * 54 + 40 : 40;
  const totalReviewCount = reviews?.length
    ? reviews?.map((el) => el?.count)?.reduce((acc, b) => acc + b)
    : 0;
  const today = new Date();
  const expiredRates = allRates?.filter(
    (item) => +today - (item?.timestamp - OFFSET) * 1000 > 600000
  );

  useEffect(() => {
    if (localStorage?.getItem(LS.FAVORITES)) {
      dispatch(setFavorites(localStorage?.getItem(LS.FAVORITES)?.split(",")));
    }
    createRatesSubscription(false, false);
    createAlertsSubscription(false, false);
    createFlightsSubscription(false, false);
    createRequestsSubscription(false, false);
    return () => {
      createRatesSubscription(false, true);
      createAlertsSubscription(false, true);
      createFlightsSubscription(false, true);
      createRequestsSubscription(false, true);
    };
  }, []);

  useEffect(() => {
    const blockAlertsTimer = setTimeout(() => setBlockRatesAlert(false), 600000);
    if (expiredRates?.length === 1 && !blockRatesAlert && isCentrifugoConnection) {
      notify.show(
        "Курсы Binance",
        `Курс ${expiredRates[0]?.name} не обновлялся более 5 минут`,
        Url.RATES
      );
      setBlockRatesAlert(true);
    }
    if (expiredRates?.length > 1 && !blockRatesAlert && isCentrifugoConnection) {
      notify.show(
        "Курсы Binance",
        `Курсы по ${expiredRates?.length} направлениям не обновлялись более 5 минут`,
        Url.RATES
      );
      setBlockRatesAlert(true);
    }
    return () => {
      clearTimeout(blockAlertsTimer);
    };
  }, [expiredRates, isCentrifugoConnection]);

  return (
    <div
      className={`${
        page !== Url.ANALYTICS && page !== `${Url.CONTROL}/${Url.CONTROL_DASHBOARD}`
          ? "side-shadow"
          : "side-shadow-dark"
      } fixed right-[-15px] top-[49px] rounded-[10px] h-[96vh] max-h-[96vh] pb-[38px] widthAnimation z-50 ${
        isOpen ? "w-[280px]" : "w-[64px] sm:w-[80px]"
      }`}>
      {isOpen && (
        <button
          onClick={() => setIsOpen(false)}
          className="absolute text-[14px] text-gray w-[40px] h-[40px] right-[20px] top-[10px] rounded-[50%] table bg-white z-[11]">{`\u279C`}</button>
      )}
      <div
        onClick={() => setIsOpen(true)}
        className={`h-full flex flex-col ${
          isOpen ? "justify-between" : "justify-start cursor-pointer"
        }`}>
        <div
          style={{ height: `calc(100% - ${ratesPadding}px)` }}
          className={`grid ${
            totalReviewCount && flights?.data?.length
              ? "grid-rows-[3fr_2fr_1fr]"
              : (!totalReviewCount && flights?.data?.length) ||
                (totalReviewCount && !flights?.data?.length)
              ? "grid-rows-[3fr_1fr]"
              : "grid-rows-1"
          } gap-8`}>
          {!!alerts?.length && (
            <SideBarSection
              count={alerts?.length ? alerts?.length : 0}
              icon={alert}
              items={alerts}
              tab="alert"
              isOpen={isOpen}
            />
          )}
          {!!flights?.data?.length && (
            <SideBarSection
              count={flights?.data?.length ? flights?.data?.length : 0}
              icon={flight}
              items={flights}
              tab="flights"
              isOpen={isOpen}
            />
          )}
          {!!totalReviewCount && (
            <SideBarSection
              count={totalReviewCount ? totalReviewCount : 0}
              icon={review}
              items={reviews}
              tab="review"
              isOpen={isOpen}
            />
          )}
        </div>
        <div className="w-full h-full mt-[14px]">
          <SideBarSection
            count={0}
            icon={chart}
            items={[]}
            tab="rates"
            isOpen={isOpen}
            isRatesFull={isRatesFull}
            setIsRatesFull={setIsRatesFull}
          />
        </div>
      </div>
    </div>
  );
};
