import { useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import devApi from "src/api/developers";
import { AddButton } from "src/components/UI/buttons/AddButton";
import { SourceServiceItem } from "./Item";

export const SourcesServicesForm = ({ items, setFn, attr }) => {
  const [isAdd, setIsAdd] = useState(false);
  const [name, setName] = useState<string>("");

  const fetchItems = async () => {
    const { response } = await fetchRequest(devApi[`get${attr}`](), { request: attr });
    if (response) {
      setFn(response);
      setIsAdd(false);
      setName("");
    }
  };

  const baseRequest = async (request, title, data) => {
    // add update delete // Создание Обновление Удаление
    const { response } = await fetchRequest(devApi[`${request}${attr}`](data), {
      request: title,
    });
    if (response) {
      await fetchItems();
    }
  };

  return (
    <div className="w-[300px] flex flex-col gap-8 pb-[30px] md:pb-[100px]">
      <AddButton
        onClick={() => setIsAdd(!isAdd)}
        title={`Добавить ${attr?.toLowerCase()?.substring(0, attr?.length - 1)}`}
      />
      <div
        className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
          isAdd ? "max-h-[1500px] opacity-100 my-8 py-[12px]" : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
        }`}>
        <div className="grid grid-cols-[80px_1fr] gap-8 items-baseline">
          <div className="font-semibold text-lightFont whitespace-pre">name</div>
          <input
            className={`w-full h-[20px] px-4 font-light rounded-sm border border-lightGray`}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <button
          className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
            !name?.length ? "opacity-0" : ""
          }`}
          disabled={!name?.length}
          onClick={() => baseRequest("add", "Создание", [{ name: name }])}>
          Добавить
        </button>
      </div>
      {items?.map((el) => (
        <SourceServiceItem key={el.id} item={el} updFn={baseRequest} />
      ))}
    </div>
  );
};
