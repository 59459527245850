import { useRef, useState } from "react";
import { Popup } from "src/components/Popup/Popup";

export const SourceServiceItem = ({ item, updFn }) => {
  const [isUpd, setUpd] = useState(false);
  const [isPopup, setPopup] = useState(false);
  const inputRef = useRef(null);

  return (
    <div
      className={`grid grid-cols-[1fr_16px] sm:grid-cols-[1fr_30px] gap-[20px] items-center rounded-lg duration-700 shadow-smooth p-[10px] text-xs text-left`}>
      <div className="relative w-full h-[20px] cursor-pointer text-xs">
        <div
          className={`absolute ${!isUpd ? "z-[1]" : "-z-10 opacity-0"} cursor-pointer`}
          onClick={() => {
            setUpd(true);

            inputRef.current.focus();
          }}>
          {item?.name}
        </div>
        <input
          className={`absolute w-full ${isUpd ? "z-[1]" : "-z-10 opacity-0"} px-4 font-light`}
          ref={inputRef}
          defaultValue={item?.name}
          onBlur={() => setUpd(false)}
          onKeyDown={async (e) => {
            if (e.keyCode === 13) {
              await updFn("update", "Обновление", [{ name: e.currentTarget.value, id: item.id }]);
              setUpd(false);
            }
          }}
        />
      </div>
      <button
        className={`duration-300 text-lightGray hover:text-lightFont`}
        onClick={() => setPopup(true)}>
        {`\u2716`}
      </button>
      {isPopup && (
        <Popup closeModal={() => setPopup(false)}>
          <div className="text-center whitespace-pre flex flex-col items-center gap-[20px]">
            <div className="font-light text-sm">
              {`Вы уверены, что хотите удалить\n `}
              <b>{item?.name}</b>?
            </div>
            <button
              className="prime-button-sm w-fit"
              onClick={async () => {
                await updFn("delete", "Удаление", item.id);
              }}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
