import { BASE_URL } from "./constants";
import api from "./http";

export default class authApi {
  static async login(data) {
    return api.post(`${BASE_URL}/auth/login`, data);
  }
  static async logout() {
    return api.post(`${BASE_URL}/auth/logout`);
  }
  static async register(data) {
    return api.post(`${BASE_URL}/auth/reg`, data);
  }
  static async refreshToken() {
    return api.patch(`${BASE_URL}/auth/refresh`);
  }
}
