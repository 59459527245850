export enum EProxyTab {
  PROXY = "proxy",
  BALANCE = "balance",
  SERVICES = "service",
}

export type TProxy = {
  id: number;
  source_id: number;
  host: string;
  port: number;
  user: string;
  password: string;
  full_view: string;
  exp: string;
  soon_exp: boolean;
  reserved_by: Array<number>;
  problems: Array<number>;

  id_from_source?: number;
  proxy_type?: EProxyType;
  proxy_version?: string;
  created_at?: string;
};
export type TProlongProxy = {
  exp: string;
  id: number;
};
export type TBaseModel = {
  id: number;
  name: string;
};
export type TSourceVersion = {
  version_name: string;
  prolong_periods: number[];
  comment: string;
};
export type TSourceModel = {
  id: number;
  name: string;
  versions: TSourceVersion[];
};
export type TProxyFilter = {
  sources: Array<number>;
  services: Array<number>;
  targets: Array<number>;
  soon_exp: boolean;
};

export type TProxySourceBalance = {
  source_id: number;
  email: string;
  balance: number;
};

export type TCreateProxyPayload = {
  count: number;
  period: number;
  country: string;
  proxy_version: string;
  proxy_type: EProxyType;
};

export enum EProxyType {
  HTTP = "HTTPS",
  SOCKSS = "SOCKS5",
}
