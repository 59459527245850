import { BASE_URL } from "./constants";

import api from "./http";

export default class bidApi {
  static async getRates() {
    return api.get(`${BASE_URL}/rates/`);
  }
  static async sendForm(data) {
    return api.post(`${BASE_URL}/rates/add-rate`, data);
  }
  static async getLimits(cityId) {
    return api.get(`${BASE_URL}/rates/city_limit/${cityId}`);
  }
  static async setLimits(cityId, data) {
    return api.post(`${BASE_URL}/rates/city_limit/${cityId}`, data);
  }
}
