import Centrifuge from "centrifuge";
import { BASE_URL, CENTRIFUGO_URL } from "../constants";
import api from "../http";
import { store } from "../../store/store";
import { setCentrifugoConnection } from "../../store/mainReducer";

export const centrifuge = new Centrifuge(`${CENTRIFUGO_URL}`, {
  debug: true,
  refreshAttempts: 1,
  onRefresh: async function () {
    await api.get(`${BASE_URL}/auth/centrifugo`).then((res) => {
      if (res && res.status >= 200 && res.status < 300) {
        centrifuge.setToken(res.data.token);
        centrifuge.connect();
      } else {
        unsubscribeAllChannels();
      }
    });
  },
  onRefreshFailed: function (ctx) {
    alert("1");
    console.log(ctx, "refresh failed 2");
    unsubscribeAllChannels();
  },
  onDisconnect: function (ctx, cb) {
    console.log(ctx, cb, "disconnect reason");
    store.dispatch(setCentrifugoConnection(false));
  },
});

export const createCentrifugeConnection = async () => {
  await api.get(`${BASE_URL}/auth/centrifugo`).then((res) => {
    if (res && res.status >= 200 && res.status < 300) {
      centrifuge.setToken(res.data.token);
    }
  });
  centrifuge.connect();
  store.dispatch(setCentrifugoConnection(true));
};

export const unsubscribeAllChannels = () => {
  centrifuge.disconnect();
};
