import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LS } from "src/api/constants";
import { selectFavorites, selectRates, setFavorites, TRates } from "src/store/statsReducer";
import { FavRow } from "./components/FavRow";
import { RateRow } from "./components/RatesRow";
import search from "src/assets/images/search.svg";

export const Rates = () => {
  const dispatch = useDispatch();
  const data = useSelector(selectRates);
  const favorites = useSelector(selectFavorites);
  const [searchInput, setSearchInput] = useState<string>();
  const [filteredArr, setFilteredArr] = useState<Array<TRates>>([]);
  const favItems = [0, 1, 2, 3, 4, 5];

  const removeItem = (item: string) => {
    if (favorites?.length) {
      localStorage.setItem(LS.FAVORITES, favorites?.filter((el) => el !== item)?.join(","));
      dispatch(setFavorites(favorites?.filter((el) => el !== item)));
    }
  };

  useEffect(() => {
    if (data?.length) {
      if (searchInput?.length) {
        setFilteredArr(findInValues(data, searchInput));
      } else {
        setFilteredArr(data);
      }
    }
  }, [data]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchInput?.length) {
        setFilteredArr(findInValues(data, searchInput));
      }
      if (searchInput?.length === 0) {
        setFilteredArr(data);
      }
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [searchInput]);

  function findInValues(arr: Array<TRates>, value: string) {
    value = String(value).toLowerCase();
    return arr.filter((o) =>
      Object.entries(o).some((entry) => String(entry[1]).toLowerCase().includes(value))
    );
  }

  return (
    <div className="w-full md:mx-[20px] md:ml-[40px] md:mr-[80px] flex flex-col-reverse md:flex-row justify-between items-center md:items-start">
      {/*курсы*/}
      <div className="md:ml-[20px] flex flex-col items-center md:items-start">
        <div className="flex items-center mb-[10px]">
          <div className="w-[289px] md:w-[350px] flex items-center relative">
            <input
              className="text-sm w-full border border-lightGray rounded-lg outline-none h-[40px] py-4 px-8"
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <img
              src={search}
              alt="Найти"
              width={20}
              height={20}
              className="absolute right-[10px]"
            />
          </div>
        </div>
        <div className="flex flex-col md:flex-row md:flex-wrap mb-[60px]">
          {filteredArr?.map((el, index) => (
            <RateRow key={index} item={el} favorites={favorites} removeItem={removeItem} />
          ))}
        </div>
      </div>
      {/*favorites*/}
      <div className="w-[289px] md:min-w-[260px] md:w-[260px] h-[220px] mb-[15px] md:mr-[120px]">
        <div
          className="table w-full h-full p-[20px]"
          style={{ display: "flex", alignItems: "center" }}>
          {favorites?.length ? (
            <div className="w-full">
              {favItems?.map((el) => (
                <div
                  key={el}
                  className="rounded-[13px] border border-select px-8 my-4 h-[26px] w-full flex items-center bg-glass-select">
                  {favorites && favorites[el] ? (
                    <FavRow item={favorites[el]} favorites={favorites} removeItem={removeItem} />
                  ) : (
                    <div className="text-[10px] text-lightGray w-full">Вектор не добавлен</div>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <div className="w-full text-sm text-lightGray text-center">
              В Вашем избранном еще ничего нет. Добавьте до 6-ти векторов, чтобы подписаться на
              свежие курсы
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
