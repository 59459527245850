import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  createBidSubscription,
  setRedactorByCentrifugo,
  deleteRedactorByCentrifugo,
} from "src/api/centrifugo/bidCentrifugo";
import { selectRedactors } from "src/store/bidReducer";
import { IRedactors, TVectorSelect } from "src/pages/Bid/models";
import { LS } from "src/api/constants";
import on from "src/assets/images/check.svg";
import off from "src/assets/images/close.svg";

export const Redactor: FC<{
  currentVector: TVectorSelect;
  setRedactorDisabled: Dispatch<SetStateAction<boolean>>;
  page: string;
}> = ({ currentVector, setRedactorDisabled, page }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const userName = localStorage.getItem(LS.USERNAME);
  const redactors = useSelector(selectRedactors) as Array<IRedactors>;
  const redactorsVectors = redactors?.map((el) => el.vector);
  const redactor = redactors?.find((el) => el.vector === currentVector?.label)?.user;

  const isBlocked = page === "bid" && redactor !== userName;
  const isAvailable = page === "bid" && (redactor === userName || !redactor);

  const editToggle = async () => {
    if (page === "bid") {
      if (isEdit) {
        setIsEdit(false);
        await deleteRedactorByCentrifugo(currentVector.label);
      } else {
        setIsEdit(true);
        await setRedactorByCentrifugo(currentVector.label, userName);
      }
    }
  };

  useEffect(() => {
    if (page === "bid") {
      if (currentVector?.label?.length > 0) {
        createBidSubscription(false, false, currentVector);
        return () => {
          createBidSubscription(false, true, currentVector);
        };
      }
    }
  }, [currentVector]);

  useEffect(() => {
    if (page === "bid") {
      if (!!redactor && redactorsVectors?.includes(currentVector?.label)) {
        setIsEdit(true);
      } else {
        setIsEdit(false);
      }
    }
  }, [redactor, redactors, currentVector]);

  useEffect(() => {
    if (page === "bid") {
      if (!!redactor && redactor !== userName) {
        setRedactorDisabled(true);
      } else {
        setRedactorDisabled(false);
      }
    }
  }, [redactor]);

  return (
    <div className={`flex flex-col fixed bottom-[40px] left-[10px]`}>
      <button
        onClick={() => setOpen(!isOpen)}
        type="button"
        className={`w-full bg-lightGray text-white font-black rounded-t-lg mr-[5px] shadow`}>
        {isOpen ? `\u25B2` : `\u25BC`}
      </button>
      <div
        className={`flex flex-col justify-center content-center relative duration-700 px-[10px] h-fit ${
          isOpen ? "pb-[10px] pt-[15px]" : isEdit && !isBlocked ? "pt-4 pb-[8px]" : "pt-4 pb-[8px]"
        } rounded-b-lg shadow-lg w-[200px] ${isEdit ? "bg-lightGray opacity-90" : "bg-bg"}`}>
        <img
          src={isEdit ? off : on}
          alt={isEdit ? "редактируется" : ""}
          width={40}
          height={40}
          className={`m-auto mb-[5px] duration-300 ease-out ${
            isOpen ? "h-[40px] opacity-100" : "h-[0] opacity-0"
          }`}
        />
        <div className={`duration-500 ${isOpen || isEdit ? "max-h-fit h-fit" : "max-h-[0] h-[0]"}`}>
          <div
            className={`duration-100 small-text ${
              isOpen ? `my-[5px] ${isEdit ? "h-[10px]" : "h-[50px]"} opacity-100 block` : "hidden"
            }`}>
            {isEdit
              ? "Редактируется пользователем"
              : `Заблокируйте редактирование ${
                  page === "bid" ? "ставки" : "автокора"
                } в данном векторе для других пользователей`}
          </div>
          <div
            className={`duration-500 ${
              isAvailable
                ? isOpen
                  ? "max-h-fit h-fit"
                  : "max-h-[0] h-[0]"
                : "max-h-[20px] h-[20px]"
            }`}>
            {redactor}
          </div>
        </div>
        {(!isBlocked || (page === "bid" && !redactor)) && (
          <button
            type="button"
            className="rounded-lg text-white text-xs bg-font font-semibold py-4"
            onClick={editToggle}>
            {page === "bid" && redactor === userName
              ? "Закончить редактирование"
              : "Закрепить для себя"}
          </button>
        )}
      </div>
    </div>
  );
};
