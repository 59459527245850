import { useState } from "react";
import { fetchRequest } from "src/helpers/fetchRequest";
import devApi from "src/api/developers";
import { TSelect } from "src/store/directionsReducer";
import { Popup } from "src/components/Popup/Popup";
import { ProxyForm } from "./ProxyForm";
import { TBaseModel, TProxy, TSourceModel } from "../types";

type TProps = {
  item: TProxy;
  sourcesOptions: Array<TSelect>;
  servicesOptions: Array<TSelect>;
  targets: Array<TBaseModel>;
  fetchProxy: () => Promise<void>;
  updateItem: (item: TProxy) => void;
  sources: TSourceModel[];
};

export const ProxyRow = ({
  item,
  sourcesOptions,
  servicesOptions,
  targets,
  fetchProxy,
  updateItem,
  sources,
}: TProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const source = sourcesOptions?.find((el) => el?.value === item?.source_id);

  const deleteProxy = async () => {
    const { response } = await fetchRequest(devApi.deleteProxy(item.id), {
      request: "Удаление прокси",
    });
    if (response) {
      await fetchProxy();
    }
  };

  return (
    <div className={`rounded-lg shadow-smooth p-[10px] pl-[20px] min-w-[300px] text-left text-xs`}>
      <div className="grid grid-cols-[16px_1fr_16px_16px] items-center sm:gap-[20px]">
        {item?.soon_exp ? (
          <div className="flex justify-center items-center rounded-full w-[20px] h-[20px] font-bold text-[#E7544E] bg-[#FFD4CE] border border-[#E7544E]">
            !
          </div>
        ) : (
          <div />
        )}
        <div>
          <div
            className={`grid grid-cols-[90px_1fr_90px_50xp] sm:grid-cols-[90px_1fr_90px_50px] sm:gap-[20px] items-center`}>
            <div>{item?.user}</div>
            <div>{item?.password}</div>
            <div>{item?.host}</div>
            <div>{item?.port}</div>
          </div>
          <div className="flex gap-4">
            {!!source?.label && (
              <div className="whitespace-pre rounded-sm bg-[#FCC815] text-[#FFF] text-[10px] px-4 font-medium h-[15px]">
                {source.label}
              </div>
            )}
            {item.problems?.map((el) => (
              <div
                key={`problem${el}`}
                className="whitespace-pre rounded-sm bg-[#E7544E] text-[#FFD4CE] text-[10px] px-4 font-medium h-[15px]">
                {targets?.find((target) => target.id === el)?.name}
              </div>
            ))}
          </div>
        </div>
        <div className="cursor-pointer" onClick={() => setIsEdit(!isEdit)}>
          {`\u270E`}
        </div>
        <div>
          <button
            className={`duration-300 text-lightGray hover:text-lightFont`}
            onClick={() => {
              setIsDelete(true);
            }}>{`\u2716`}</button>
        </div>
      </div>
      {isEdit && (
        <ProxyForm
          item={item}
          source={source}
          sourcesOptions={sourcesOptions}
          servicesOptions={servicesOptions}
          targets={targets}
          fetchProxy={fetchProxy}
          updateItem={updateItem}
          sources={sources}
        />
      )}
      {isDelete && (
        <Popup closeModal={() => setIsDelete(false)}>
          <div className="p-[10px] flex flex-col gap-[20px] whitespace-pre text-center">
            {`Уверены, что хотите\n удалить ${item?.host}:${item?.port}?`}
            <button className="prime-button-sm" onClick={deleteProxy}>
              Удалить
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};
