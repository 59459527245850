import { Dispatch, SetStateAction } from "react";
import { convertToShortDate } from "src/helpers/date";
import { TSelect } from "src/store/directionsReducer";
import { TTemplate, TText } from "../types";

type TProps = {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  disabled: boolean;
  disabledTemplate: boolean;
  activeText: boolean;
  setPopup: Dispatch<SetStateAction<boolean>>;
  submitTemplate: () => void;
  template: TTemplate | null;
  site: TSelect | null;
  setCaret: Dispatch<SetStateAction<number | null>>;
  isTemplate: boolean;
  resText: TText | null;
  isNotNeededVectors: boolean;
};

export const TextBlock = ({
  text,
  setText,
  disabled,
  disabledTemplate,
  activeText,
  setPopup,
  submitTemplate,
  template,
  site,
  setCaret,
  isTemplate,
  resText,
  isNotNeededVectors,
}: TProps) => {
  const getCaret = (el) => {
    setCaret(el?.selectionStart);
  };

  if (!!site?.value) {
    return (
      <div className={`relative w-full h-full min-h-[300px] rounded-sm border border-lightGray`}>
        <textarea
          className={`h-full w-full min-h-[300px] py-8 px-[16px]`}
          id="textarea"
          value={text}
          onClick={() => getCaret(document.getElementById("textarea"))}
          onKeyUp={() => getCaret(document.getElementById("textarea"))}
          onChange={(e) => {
            setText(e.target.value);
          }}
        />
        {!text?.length && !disabled && (
          <div className="absolute inset-1 top-[150px] h-fit text-center text-[#BABAC3] text-[10px]">
            <div>Нет текста для вектора. Создайте текст</div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row gap-4 lg:justify-between py-4 px-4 pb-[20px]">
          <div className="flex gap-4">
            {!isNotNeededVectors && <button
              className={`bg-light px-[16px] border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
                disabledTemplate ? "opacity-10" : ""
              }`}
              disabled={disabledTemplate}
              onClick={submitTemplate}>
              {!!template?.text?.length
                ? "Обновить шаблон"
                : !isTemplate
                ? "Сохранить как шаблон"
                : "Создать шаблон"}
            </button>}
            {!isTemplate && (
              <button
                className={`px-[16px] border font-semibold rounded-sm duration-300 ${
                  disabled || !text.length ? "opacity-10" : ""
                } ${
                  activeText
                    ? "bg-select border-pistachio text-[#32A56E]"
                    : "bg-light border-lightGray text-lightFont"
                }`}
                disabled={disabled || !text.length}
                onClick={() => setPopup(true)}>
                Отправить
              </button>
            )}
          </div>
        </div>
        {(!!template || !!resText) && (
          <div className="text-lightFont flex gap-8 pr-8 -mt-8 justify-end">
            Последнее изменение
            {((isTemplate && !!template?.created_by_user) ||
              (!isTemplate && !!resText?.created_by_user)) && (
              <span className="font-semibold">
                {isTemplate ? template?.created_by_user : resText?.created_by_user}
              </span>
            )}
            {((isTemplate && !!template?.created_at) || (!isTemplate && !!resText?.created_at)) && (
              <span className="font-semibold">
                {convertToShortDate(
                  +new Date(
                    isTemplate ? (template?.created_at as string) : (resText?.created_at as string)
                  ) /
                    1000 +
                    3 * 3600
                )}
              </span>
            )}
          </div>
        )}
      </div>
    );
  } else {
    return <div className={`w-full h-full min-h-[334px] rounded-sm bg-stale opacity-60`} />;
  }
};
