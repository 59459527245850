import api from "./http";
import { BASE_URL } from "./constants";

export const rateApi = {
  getProfRate: async () => {
    const req = await api.get(`${BASE_URL}/prof-forms/rate`);
    return req.data;
  },
  postProfRate: async (data) => {
    const req = await api.post(`${BASE_URL}/prof-forms/rate`, data);
    return req.data;
  },
  getProfLastHistory: async () => {
    const req = await api.get(`${BASE_URL}/prof-forms/rate/last-batch`);
    return req.data;
  },
};
