import { AxiosResponse } from "axios";
import { dispatch } from "../store/store";
import { setNotific } from "../store/mainReducer";

interface Message {
  message?: string;
}

interface FetchRequestOptions {
  request?: string;
  errorMessage?: string;
}

export const fetchRequest = async <T>(
  axiosMethod: Promise<AxiosResponse<T & Message> & Message>,
  options?: FetchRequestOptions
) => {
  /* eslint-disable */
  let response: T | null = null;
  let error = null as any;
  let isLoading = true;
  let status = null as any;
  /* eslint-enable */

  const fetchData = async () => {
    await axiosMethod
      .then((res) => {
        if (!(res.status >= 200 && res.status < 300) && res.message) {
          error = { statusText: res.message, status: res.status };
        }
        if (!(res.status >= 200 && res.status < 300) && res.data.message) {
          error = { statusText: res.data.message, status: res.status };
        }
        if (res.status === 404) {
          error = { statusText: "Сервис не подключен", status: res.status };
        }
        if (res && res.status >= 200 && res.status < 300) {
          response = res.data;
        }
        if (res.status === 500) {
          error = { statusText: "Ошибка сервера", status: 500 };
        }
        status = res.status;
      })
      .catch((err) => {
        if (!!err.message) {
          error = { statusText: err.message, status: 500 };
        } else {
          error = { statusText: "Ошибка сервера", status: 500 };
        }
      })
      .finally(() => {
        isLoading = false;
      });
  };
  await fetchData();

  if (error && options && options.request) {
    dispatch(
      setNotific({
        type: "error",
        message: options.errorMessage || error.statusText,
        request: options.request,
      })
    );
  }

  return { response, error, isLoading, status };
};
