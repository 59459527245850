import { useMemo } from "react";
import { NavLink } from "react-router-dom";
import { Url } from "src/api/constants";
import { RateCard } from "./RateCard";
import { useSelector } from "react-redux";
import { selectFavorites, selectRates } from "src/store/statsReducer";

export const RatesGroup = ({ isFull, setIsFull }) => {
  const vectors = useSelector(selectFavorites);
  const allRates = useSelector(selectRates);
  const selectedRates = useMemo(
    () =>
      allRates
        ?.filter((el) => vectors?.includes(`${el?.name} - ${el.sources[0]}`))
        ?.sort((a, b) => (`${b.name}_${b.sources[0]}` > `${a.name}_${a.sources[0]}` ? -1 : 1)),
    [allRates, vectors]
  );

  return (
    <div className="flex flex-col self-end gap-4 justify-end relative left-[27px] min-w-[225px] h-fit">
      {isFull && selectedRates?.map((el, index) => <RateCard key={index} item={el} />)}
      <div className="flex justify-between">
        {selectedRates?.length > 1 && (
          <button onClick={() => setIsFull(!isFull)} className="prime-button-sm z-[2]">
            {isFull ? "Скрыть" : "Показать"}
          </button>
        )}
        <button className="prime-button-sm z-[2] rounded-md">
          <NavLink
            to={Url.RATES}
            className={({ isActive }) =>
              !isActive ? "" : "tracking-wider text-white font-extrabold"
            }>
            В курсы {`\u279C`}
          </NavLink>
        </button>
      </div>
    </div>
  );
};
