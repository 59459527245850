import { useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { fetchRequest } from "src/helpers/fetchRequest";
import { selectFilter } from "src/helpers/formHelpers";
import devApi from "src/api/developers";
import { TSelect } from "src/store/directionsReducer";
import ReactSelect, { createFilter } from "react-select";
import { AddButton } from "src/components/UI/buttons/AddButton";
import { DateField } from "src/components/FormElements/DateField";
import { Field } from "src/components/FormElements/Field";

export const CreateProxy = ({ fetchProxy, sourcesOptions, servicesOptions }) => {
  const methods = useForm({ mode: "onChange" });
  const [isAdd, setIsAdd] = useState(false);
  const [source, setSource] = useState<number>();
  const [services, setServices] = useState<Array<number>>([]);
  const [exp, setExp] = useState<string>(new Date().toUTCString());
  const errors = Object.keys(methods.formState.errors);
  const disabled = !source || !exp || !!errors.length;

  const addProxy = async (e) => {
    const { response } = await fetchRequest(
      devApi.addProxy([
        {
          ...e,
          port: +e.port,
          source_id: source,
          reserved_by: services,
          id_from_source: !!e.id_from_source?.length ? +e.id_from_source : null,
          exp: exp,
        },
      ]),
      { request: "Добавление Прокси" }
    );
    if (response) {
      await fetchProxy();
      methods.reset();
      setIsAdd(false);
    }
  };

  return (
    <div className="w-full flex flex-col my-[10px]">
      <AddButton title="Добавить прокси" onClick={() => setIsAdd(!isAdd)} />
      <FormProvider {...methods}>
        <form
          className={`duration-500 overflow-hidden flex flex-col gap-8 text-xs px-[10px] ${
            isAdd
              ? "max-h-[1500px] opacity-100 my-8 py-[12px]"
              : "max-h-0 opacity-0 mt-0 -mb-8 py-4"
          }`}
          onSubmit={methods.handleSubmit(addProxy)}>
          <div className="grid grid-cols-[68px_1fr] gap-8 items-baseline">
            <div className="text-right">сервисы</div>
            <div>
              <ReactSelect
                classNamePrefix="SquareSelect"
                className="brightness-[1.15] z-[1]"
                placeholder="Выберите сервисы"
                options={servicesOptions}
                filterOption={createFilter(selectFilter)}
                onChange={(e: TSelect) => {
                  if (!services?.includes(e.value)) {
                    setServices([...services, e.value]);
                  }
                }}
              />
              <div className="flex flex-wrap gap-4 ml-[1px] mt-4">
                {!!services?.length &&
                  services?.map((el) => (
                    <div
                      key={el}
                      className="flex gap-4 items-center whitespace-pre rounded-sm bg-[#3BC57A] text-[#BBFFDA] text-[10px] pl-4 pr-8 font-medium h-[15px]">
                      {servicesOptions?.find((service) => service.value === el)?.label}
                      <button
                        type="button"
                        className="relative left-2 rotate-45 text-[12px]"
                        onClick={() => {
                          setServices(services?.filter((service) => service !== el));
                        }}>
                        +
                      </button>
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-[68px_1fr] gap-8 items-baseline">
            <div className="text-right">источник</div>
            <ReactSelect
              classNamePrefix="SquareSelect"
              className="brightness-[1.15]"
              placeholder="Выберите источник"
              options={sourcesOptions}
              filterOption={createFilter(selectFilter)}
              onChange={(e: TSelect) => setSource(e.value)}
            />
          </div>
          <Field title="username" name="user" defaultValue="" required />
          <Field title="password" name="password" defaultValue="" required />
          <Field title="host" name="host" defaultValue="" required />
          <Field title="port" name="port" defaultValue="" required isNumber isPositive />
          <Field
            title="id из источника"
            name="id_from_source"
            defaultValue=""
            isNumber
            isPositive
          />
          <DateField title="expired" setValue={setExp} value={exp} />
          <button
            className={`bg-light self-end px-[16px] mt-8 border border-lightGray text-lightFont font-semibold rounded-sm duration-300 ${
              disabled ? "opacity-0" : ""
            }`}
            disabled={disabled}>
            Добавить
          </button>
        </form>
      </FormProvider>
    </div>
  );
};
