import { cutter } from "src/helpers/currencyHelper";
import { sourceColor } from "src/helpers/tetrisHelper";
import { TRates } from "src/store/statsReducer";
import { TimeStamp } from "../../UI/timestamp/TimeStamp";

export const RateCard = ({ item }: { item: TRates }) => {
  return (
    <div
      style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      className={`table shadow-sm bg-font text-white my-0 py-[5px] px-[10px] w-[225px] max-w-[225px] rounded-md`}>
      <div className="font-black text-sm text-left">
        {cutter(item?.name)}
        <div
          className={`text-[9px] leading-3 mb-0`}
          style={{ color: sourceColor(item?.sources[0]) }}>
          {item?.sources[0]}
        </div>
      </div>
      <div className="w-[100px] text-[12px] text-right font-bold flex flex-col items-end relative right-[-5px]">
        <div className="relative right-[6px]">{item?.course}</div>
        <TimeStamp item={item} isGreen />
      </div>
    </div>
  );
};
